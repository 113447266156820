<!--
 * @Description: 客户开票信息变更详情
 * @Author: LiangYiNing
 * @Date: 2022-01-11 10:46:11
 * @LastEditTime: 2022-04-20 13:45:29
 * @LastEditors: LiangYiNing
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <!-- 开票信息   start -->
          <div class="baseInfo-box">
            <div class="edit-title">开票信息</div>
            <el-form
              ref="corpShiftForm"
              label-width="140px"
              label-position="right"
              :inline="true"
              :model="corpShiftForm"
              :disabled="!!corpShiftForm.billingCorpId"
              class="editForm"
            >
              <el-form-item label="审批单号" prop="approvalCode">
                <el-input
                  v-model="approvalCode"
                  disabled
                  placeholder="审批单号, 系统生成"
                ></el-input>
              </el-form-item>
              <el-form-item label="开票ID" prop="billingCorpId">
                <el-input v-model="corpShiftForm.billingCorpId"></el-input>
              </el-form-item>
              <el-form-item label="开票名称" prop="billingCorpName">
                <el-input v-model="corpShiftForm.billingCorpName"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="edit-wrapper__footer">
          <el-button class="cancel" @click="handleGoBack">取消</el-button>
          <el-button type="primary" @click="handleGoBack">确定</el-button>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import goBack from "@/mixins/goBack";
import { getInvoiceDetailBefore } from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "InvoiceChangeDetails",
  mixins: [base, goBack],
  data() {
    return {
      selectCustomerDialogVisible: false,
      approvalCode: "", //审批单号
      corpShiftForm: {
        billingCorpId: "", // 开票ID
        billingCorpName: "" // 开票名称
      }
    };
  },
  computed: {},
  watch: {},
  created() {
    let hasParamsRoute = this.getHasParamsRoute();
    this.approvalCode = hasParamsRoute.params.approvalCode;
    let id =
      hasParamsRoute.params.id || hasParamsRoute.params.approvalBusinessId;
    this.corpShiftForm.billingCorpId = id;
    if (this.corpShiftForm.billingCorpId) {
      this.queryDetail();
    }
  },
  methods: {
    //获取开票改变审批详情
    async queryDetail() {
      try {
        let billingCorpId = this.corpShiftForm.billingCorpId;
        let res = await getInvoiceDetailBefore({ billingCorpId });
        let data = this.getFreezeResponse(res, {
          path: "data"
        });
        this.corpShiftForm = JSON.parse(JSON.stringify(data));
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped></style>
